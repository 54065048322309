<template>
  <div>
    <liste
      label-bouton-ajout-element="Créer une ressource"
      :nom-page="nomPage"
      key-name="idRessource"
      default-sort-by="prenom"
      :items-table="listeUtilisateursFormateeFiltree"
      :loading="loading"
      :titres-table="headers"
      :mobile-breakpoint="740"
      @ajout-element="ouvrirFormulaireRessource([{ idRessource: 0 }])"
    >
      <template #filtre>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on">
              <v-checkbox
                v-model="afficherAussiLesRessourcesDesactivees"
                :test-auto="nomPage + '_filtre_ressourcesDesactivees'"
                hide-details
                class="mt-0 pt-0 mx-1"
                on-icon="mdi-account-tie"
                off-icon="mdi-account-tie-outline"
              ></v-checkbox>
            </span>
          </template>
          <span v-if="afficherAussiLesRessourcesDesactivees"
            >Masquer les ressources désactivées</span
          >
          <span v-else>Afficher les ressources désactivées</span>
        </v-tooltip>
      </template>
      <template #actions="actionsSlotProps">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              small
              icon
              class="mx-2"
              v-bind="attrs"
              @click="
                redirigerVersPointageRessource(actionsSlotProps.item.ressources)
              "
              v-on="on"
            >
              <v-icon>mdi-calendar-month</v-icon>
            </v-btn>
          </template>
          <span>Accéder aux pointages de la ressource</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              small
              icon
              class="mx-2"
              v-bind="attrs"
              @click="
                ouvrirFormulaireRessource(actionsSlotProps.item.ressources)
              "
              v-on="on"
            >
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </template>
          <span>Modifier la ressource</span>
        </v-tooltip>
      </template>
    </liste>
  </div>
</template>
<script>
import Liste from "@/views/ListeGenerique";
import { mapActions, mapGetters, mapState } from "vuex";
import { EventHub } from "@/event-hub.js";

export default {
  components: {
    Liste,
  },
  data: () => ({
    loading: true,
    afficherAussiLesRessourcesDesactivees: false,
    nomPage: "ressource",
  }),
  computed: {
    ...mapState("entreprise", ["utilisateurs", "entreprises", "roles"]),
    ...mapState("utilisateur", ["utilisateur"]),
    ...mapGetters("entreprise", [
      "getNomEntrepriseByidRessource",
      "getActifByidRessource",
    ]),
    ...mapGetters("utilisateur", ["nomRole"]),
    headers: function() {
      // not using cellClass in headers has it doesn't work for mobile in Vuetify 2.4.0
      let headers = [
        { text: "Nom", value: "nom", align: "start", width: "150px" },
        { text: "Prénom", value: "prenom", width: "150px" },
        { text: "E-mail", value: "mail" },
        {
          text: "Rôle",
          value: "role",
          sortable: true,
          align: "center",
          width: "90px",
        },
        {
          text: "Actions",
          value: "actions",
          sortable: false,
          align: "center",
          width: "110px",
        },
      ];
      console.log(this.utilisateur.ressources.length);
      if (this.utilisateur.ressources.length > 1 || this.nomRole === "ADMIN") {
        headers.splice(3, 0, {
          text: "Entreprise",
          value: "entreprise",
        });
      }
      if (this.afficherAussiLesRessourcesDesactivees) {
        headers.splice(4, 0, {
          text: "État du compte",
          value: "actif",
          sortable: true,
          align: "center",
          width: "140px",
        });
      }
      return headers;
    },
    listeUtilisateursFormateeFiltree: function() {
      const listeUtilisateursFormatee = [];
      for (const [idRessource, utilisateur] of Object.entries(
        this.utilisateurs
      )) {
        {
          listeUtilisateursFormatee.push({
            ...utilisateur,
            actif: this.getActifByidRessource(idRessource),
            entreprise: this.getNomEntrepriseByidRessource(idRessource),
            role: this.roles[utilisateur.idRole].nomRole,
          });
        }
      }

      let listeUtilisateursFormateeFiltree = listeUtilisateursFormatee;

      if (!this.afficherAussiLesRessourcesDesactivees) {
        listeUtilisateursFormateeFiltree = listeUtilisateursFormateeFiltree.filter(
          (utilisateur) => utilisateur.actif
        );
      }
      return listeUtilisateursFormateeFiltree;
    },
  },
  watch: {
    //
  },
  created() {
    this.recupererUtilisateurs()
      .catch((err) => console.log(err))
      .finally(() => (this.loading = false));
  },
  mounted() {
    //
  },
  methods: {
    ...mapActions("entreprise", ["recupererUtilisateurs"]),
    ...mapActions("utilisateur", ["setRessourcesSelectionnee"]),
    ouvrirFormulaireRessource(Ressources) {
      EventHub.$emit("AFFICHER_FORMULAIRE", {
        type: "ressource",
        id: Ressources[0].idRessource,
      });
    },
    redirigerVersPointageRessource(Ressources) {
      this.setRessourcesSelectionnee(Ressources);
      this.$router.push("/pointages");
    },
  },
};
</script>
<style>
.role {
  padding: 2px 4px 2px 4px;
  border-radius: 20px;
  color: white;
}

.etat {
  padding: 2px 4px 2px 4px;
  border-radius: 20px;
}

.actif {
  color: #6bd227;
  background-color: #d1f9d3;
}
.desactive {
  color: #e94c3a;
  background-color: #fadcd2;
}

/* Gestion affichage mobile des chips */
.v-data-table__mobile-row__cell .role,
.v-data-table__mobile-row__cell .etat {
  padding: 4px 16px 4px 16px;
}
</style>
