var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('liste',{attrs:{"label-bouton-ajout-element":"Créer une ressource","nom-page":_vm.nomPage,"key-name":"idRessource","default-sort-by":"prenom","items-table":_vm.listeUtilisateursFormateeFiltree,"loading":_vm.loading,"titres-table":_vm.headers,"mobile-breakpoint":740},on:{"ajout-element":function($event){return _vm.ouvrirFormulaireRessource([{ idRessource: 0 }])}},scopedSlots:_vm._u([{key:"filtre",fn:function(){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('v-checkbox',{staticClass:"mt-0 pt-0 mx-1",attrs:{"test-auto":_vm.nomPage + '_filtre_ressourcesDesactivees',"hide-details":"","on-icon":"mdi-account-tie","off-icon":"mdi-account-tie-outline"},model:{value:(_vm.afficherAussiLesRessourcesDesactivees),callback:function ($$v) {_vm.afficherAussiLesRessourcesDesactivees=$$v},expression:"afficherAussiLesRessourcesDesactivees"}})],1)]}}])},[(_vm.afficherAussiLesRessourcesDesactivees)?_c('span',[_vm._v("Masquer les ressources désactivées")]):_c('span',[_vm._v("Afficher les ressources désactivées")])])]},proxy:true},{key:"actions",fn:function(actionsSlotProps){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.redirigerVersPointageRessource(actionsSlotProps.item.ressources)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-calendar-month")])],1)]}}],null,true)},[_c('span',[_vm._v("Accéder aux pointages de la ressource")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.ouvrirFormulaireRessource(actionsSlotProps.item.ressources)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v("Modifier la ressource")])])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }